import React, { Component } from "react";
import Main from "./Main";
import Sidebar from "react-sidebar";
import SB from "./Sidebar";
import { Navbar } from "react-bootstrap";
import { AuthSession } from "./Auth";

const mql = window.matchMedia("(min-width: 450px)");


class App extends Component {
	constructor(props) {
		super(props);

		this.state = {
			sidebarDocked: mql.matches,
			sidebarOpen: false,
			auth: new AuthSession(false, null, [], [])
		};

		this.mediaQueryChanged = this.mediaQueryChanged.bind(this);
		this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
	}

	componentWillMount() {
		mql.addListener(this.mediaQueryChanged);
	}

	componentWillUnmount() {
		mql.removeListener(this.mediaQueryChanged);
	}

	onSetSidebarOpen(open) {
		this.setState({ sidebarOpen: open });
	}

	mediaQueryChanged() {
		this.setState({ sidebarDocked: mql.matches, sidebarOpen: false });
	}

	render() {
		var noSidebarLinkChecks = [
			window.location.pathname !== "/",
			window.location.pathname.indexOf("newsroom") === -1,
			window.location.pathname.indexOf("invoices/pay") === -1,
			window.location.pathname !== "/education",
			window.location.pathname.indexOf("legal") === -1,
			window.location.pathname.indexOf("signup") === -1,
			window.location.pathname.indexOf("status") === -1,
		];
		return (
			<div style={{ backgroundColor: "transparent" }}>
				{noSidebarLinkChecks.indexOf(false) === -1 ? <Sidebar
					sidebar={<SB />}
					shadow={true}
					open={this.state.sidebarOpen}
					docked={this.state.sidebarDocked}
					onSetOpen={this.onSetSidebarOpen}
					styles={{ sidebar: { background: mql.matches ? "transparent" : "white" } }}
				>
					<div style={{ display: mql.matches ? "none" : "block", background: "white" }}>
						<Navbar>
							<Navbar.Brand onClick={() => this.onSetSidebarOpen(true)}>
								Snapps - Open Menu
							</Navbar.Brand>
							<Navbar.Toggle />
						</Navbar>
					</div>
					<Main />
				</Sidebar> : <Main />}
			</div>
		);
	}
}

export default App;
