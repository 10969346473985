import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
// import Route from "./Route";

class Loading extends Component {
	render() {
		return <p>Loading...</p>;
	}
}

class DynamicImport extends Component {
	state = {
		component: null
	}
	componentDidMount() {
		this.props.load()
			.then((component) => {
				this.setState(() => ({
					component: component.default ? component.default : component
				}))
			})
	}
	render() {
		return this.props.children(this.state.component)
	}
}

const Home = (props) => (
	<DynamicImport load={() => import("./Home")}>
		{(Component) => Component === null
			? <Loading /> : <Component {...props} />}
	</DynamicImport>
)
const Education = (props) => (
	<DynamicImport load={() => import("./Education")}>
		{(Component) => Component === null
			? <Loading /> : <Component {...props} />}
	</DynamicImport>
)
const Login = (props) => (
	<DynamicImport load={() => import("./Login")}>
		{(Component) => Component === null
			? <Loading /> : <Component {...props} />}
	</DynamicImport>
)
const SignUp = (props) => (
	<DynamicImport load={() => import("./Signup")}>
		{(Component) => Component === null
			? <Loading /> : <Component {...props} />}
	</DynamicImport>
)
const CategoryList = (props) => (
	<DynamicImport load={() => import("./Categories/list")}>
		{(Component) => Component === null
			? <Loading /> : <Component {...props} />}
	</DynamicImport>
)
const CreateCategory = (props) => (
	<DynamicImport load={() => import("./Categories/create")}>
		{(Component) => Component === null
			? <Loading /> : <Component {...props} />}
	</DynamicImport>
)
const ProductList = (props) => (
	<DynamicImport load={() => import("./Products/list")}>
		{(Component) => Component === null
			? <Loading /> : <Component {...props} />}
	</DynamicImport>
)
const NewsList = (props) => (
	<DynamicImport load={() => import("./News/list")}>
		{(Component) => Component === null
			? <Loading /> : <Component {...props} />}
	</DynamicImport>
)
const ItemTime = (props) => (
	<DynamicImport load={() => import("./Telemetry/itemtime")}>
		{(Component) => Component === null
			? <Loading /> : <Component {...props} />}
	</DynamicImport>
)
const Dashboard = (props) => (
	<DynamicImport load={() => import("./Telemetry/dashboard")}>
		{(Component) => Component === null
			? <Loading /> : <Component {...props} />}
	</DynamicImport>
)
const LanguageDashboard = (props) => (
	<DynamicImport load={() => import("./Telemetry/language_dashboard")}>
		{(Component) => Component === null
			? <Loading /> : <Component {...props} />}
	</DynamicImport>
)
const UpdateProfile = (props) => (
	<DynamicImport load={() => import("./Profile/updateDetails")}>
		{(Component) => Component === null
			? <Loading /> : <Component {...props} />}
	</DynamicImport>
)
const AdminPanel = (props) => (
	<DynamicImport load={() => import("./Admin/panel")}>
		{(Component) => Component === null
			? <Loading /> : <Component {...props} />}
	</DynamicImport>
)
const Notify = (props) => (
	<DynamicImport load={() => import("./Admin/notify")}>
		{(Component) => Component === null
			? <Loading /> : <Component {...props} />}
	</DynamicImport>
)
const SessionList = (props) => (
	<DynamicImport load={() => import("./Admin/sessions/list")}>
		{(Component) => Component === null
			? <Loading /> : <Component {...props} />}
	</DynamicImport>
)
const StatusList = (props) => (
	<DynamicImport load={() => import("./Admin/status/list")}>
		{(Component) => Component === null
			? <Loading /> : <Component {...props} />}
	</DynamicImport>
)
const Rewards = (props) => (
	<DynamicImport load={() => import("./Rewards/dashboard")}>
		{(Component) => Component === null
			? <Loading /> : <Component {...props} />}
	</DynamicImport>
)
const SupportList = (props) => (
	<DynamicImport load={() => import("./Support/list")}>
		{(Component) => Component === null
			? <Loading /> : <Component {...props} />}
	</DynamicImport>
)
const SupportThread = (props) => (
	<DynamicImport load={() => import("./Support/thread")}>
		{(Component) => Component === null
			? <Loading /> : <Component {...props} />}
	</DynamicImport>
)
const SurveyList = (props) => (
	<DynamicImport load={() => import("./Survey/list")}>
		{(Component) => Component === null
			? <Loading /> : <Component {...props} />}
	</DynamicImport>
)
const SurveyResponses = (props) => (
	<DynamicImport load={() => import("./Survey/responses")}>
		{(Component) => Component === null
			? <Loading /> : <Component {...props} />}
	</DynamicImport>
)
const Changelog = (props) => (
	<DynamicImport load={() => import("./Support/changelog")}>
		{(Component) => Component === null
			? <Loading /> : <Component {...props} />}
	</DynamicImport>
)
const LanguageList = (props) => (
	<DynamicImport load={() => import("./Language/list")}>
		{(Component) => Component === null
			? <Loading /> : <Component {...props} />}
	</DynamicImport>
)
const CreateLanguageCategory = (props) => (
	<DynamicImport load={() => import("./Language/create")}>
		{(Component) => Component === null
			? <Loading /> : <Component {...props} />}
	</DynamicImport>
)
const WordList = (props) => (
	<DynamicImport load={() => import("./Words/list")}>
		{(Component) => Component === null
			? <Loading /> : <Component {...props} />}
	</DynamicImport>
)
const LocationsList = (props) => (
	<DynamicImport load={() => import("./Directory/Locations/list")}>
		{(Component) => Component === null
			? <Loading /> : <Component {...props} />}
	</DynamicImport>
)
const IndustriesList = (props) => (
	<DynamicImport load={() => import("./Directory/Industries/list")}>
		{(Component) => Component === null
			? <Loading /> : <Component {...props} />}
	</DynamicImport>
)
const CompaniesList = (props) => (
	<DynamicImport load={() => import("./Directory/Companies/list")}>
		{(Component) => Component === null
			? <Loading /> : <Component {...props} />}
	</DynamicImport>
)

const VisitorsList = (props) => (
	<DynamicImport load={() => import("./Directory/Visitors/list")}>
		{(Component) => Component === null
			? <Loading /> : <Component {...props} />}
	</DynamicImport>
)

const InvoicesList = (props) => (
	<DynamicImport load={() => import("./Invoice/list")}>
		{(Component) => Component === null
			? <Loading /> : <Component {...props} />}
	</DynamicImport>
)
const PostDetails = (props) => (
	<DynamicImport load={() => import("./News/postDetails")}>
		{(Component) => Component === null
			? <Loading /> : <Component {...props} />}
	</DynamicImport>
)
const LegalHome = (props) => (
	<DynamicImport load={() => import("./Legal/LegalHome")}>
		{(Component) => Component === null
			? <Loading /> : <Component {...props} />}
	</DynamicImport>
)
const TOC = (props) => (
	<DynamicImport load={() => import("./Legal/TOC")}>
		{(Component) => Component === null
			? <Loading /> : <Component {...props} />}
	</DynamicImport>
)
const PrivacyPolicy = (props) => (
	<DynamicImport load={() => import("./Legal/PrivacyPolicy")}>
		{(Component) => Component === null
			? <Loading /> : <Component {...props} />}
	</DynamicImport>
)
const Disclaimer = (props) => (
	<DynamicImport load={() => import("./Legal/Disclaimer")}>
		{(Component) => Component === null
			? <Loading /> : <Component {...props} />}
	</DynamicImport>
)
const DisclosurePolicy = (props) => (
	<DynamicImport load={() => import("./Legal/DisclosurePolicy")}>
		{(Component) => Component === null
			? <Loading /> : <Component {...props} />}
	</DynamicImport>
)
const CookiePolicy = (props) => (
	<DynamicImport load={() => import("./Legal/CookiePolicy")}>
		{(Component) => Component === null
			? <Loading /> : <Component {...props} />}
	</DynamicImport>
)
const FileList = (props) => (
	<DynamicImport load={() => import("./MediaLibrary/list")}>
		{(Component) => Component === null
			? <Loading /> : <Component {...props} />}
	</DynamicImport>
)
const AssetList = (props) => (
	<DynamicImport load={() => import("./AssetManagement/list")}>
		{(Component) => Component === null
			? <Loading /> : <Component {...props} />}
	</DynamicImport>
)
const PayInvoice = (props) => (
	<DynamicImport load={() => import("./Invoice/pay")}>
		{(Component) => Component === null
			? <Loading /> : <Component {...props} />}
	</DynamicImport>
)
const SiteList = (props) => (
	<DynamicImport load={() => import("./AsTrack/Site/list")}>
		{(Component) => Component === null
			? <Loading /> : <Component {...props} />}
	</DynamicImport>
)
const FloorList = (props) => (
	<DynamicImport load={() => import("./AsTrack/Floor/list")}>
		{(Component) => Component === null
			? <Loading /> : <Component {...props} />}
	</DynamicImport>
)
const UploadList = (props) => (
	<DynamicImport load={() => import("./AsTrack/Upload/list")}>
		{(Component) => Component === null
			? <Loading /> : <Component {...props} />}
	</DynamicImport>
)
const ReleaseList = (props) => (
	<DynamicImport load={() => import("./Admin/releases/list")}>
		{(Component) => Component === null
			? <Loading /> : <Component {...props} />}
	</DynamicImport>
)
const MyInvoicesList = (props) => (
	<DynamicImport load={() => import("./Invoice/myInvoices")}>
		{(Component) => Component === null
			? <Loading /> : <Component {...props} />}
	</DynamicImport>
)
const Forgot = (props) => (
	<DynamicImport load={() => import("./Profile/forgot")}>
		{(Component) => Component === null
			? <Loading /> : <Component {...props} />}
	</DynamicImport>
)
const NewPassword = (props) => (
	<DynamicImport load={() => import("./Profile/new_password")}>
		{(Component) => Component === null
			? <Loading /> : <Component {...props} />}
	</DynamicImport>
)
const StatusListPage = (props) => (
	<DynamicImport load={() => import("./Support/status")}>
		{(Component) => Component === null
			? <Loading /> : <Component {...props} />}
	</DynamicImport>
)

const Main = () => (
	<main style={{ overflow: "hidden" }}>
		<Switch>
			<Route exact path="/" component={Home} />
			<Route path="/education" component={Education} />
			<Route path="/login" component={Login} />
			<Route path="/signup/:referrer" component={SignUp} />
			<Route path="/signup" component={SignUp} />
			<Route path="/legal/toc" component={TOC} />
			<Route path="/legal/privacy-policy" component={PrivacyPolicy} />
			<Route path="/legal/disclaimer" component={Disclaimer} />
			<Route path="/legal/disclosure" component={DisclosurePolicy} />
			<Route path="/legal/cookie" component={CookiePolicy} />
			<Route path="/legal" component={LegalHome} />
			<Route path="/forgot/:token" component={NewPassword} />
			<Route path="/forgot" component={Forgot} />
			<Route path="/newsroom/:appID/:postID" component={PostDetails} />
			<Route path="/invoices/pay/:userID/:invoiceID" component={PayInvoice} />
			<Route path="/company/:companyID/site/:siteID/floor/:floorID" component={UploadList} />
			<Route path="/company/:companyID/site/:siteID" component={FloorList} />
			<Route path="/company/:companyID/sites" component={SiteList} />
			<Route path="/products/create" component={CreateCategory} />
			<Route path="/products/:category" component={ProductList} />
			<Route path="/products" component={CategoryList} />
			<Route path="/posts" component={NewsList} />
			<Route path="/analytics/itemtime/:type/:id/:title/:label" component={ItemTime} />
			<Route path="/analytics/itemtime/:type/:id" component={ItemTime} />
			<Route path="/analytics/language" component={LanguageDashboard} />
			<Route path="/analytics" component={Dashboard} />
			<Route path="/about" component={UpdateProfile} />
			<Route path="/myInvoices" component={MyInvoicesList} />
			<Route path="/adminpanel/notify/:template" component={Notify} />
			<Route path="/adminpanel/releases" component={ReleaseList} />
			<Route path='/adminpanel/status' component={StatusList} />
			<Route path="/adminpanel/sessions/:userID" component={SessionList} />
			<Route path="/adminpanel/sessions" component={SessionList} />
			<Route path="/adminpanel" component={AdminPanel} />
			<Route path="/reward" component={Rewards} />
			<Route path="/support/:ticket" component={SupportThread} />
			<Route path="/support" component={SupportList} />
			<Route path="/surveys/:surveyId" component={SurveyResponses} />
			<Route path="/surveys" component={SurveyList} />
			<Route path="/changelog" component={Changelog} />
			<Route path="/status" component={StatusListPage} />
			<Route path="/languages/create" component={CreateLanguageCategory} />
			<Route path="/languages/:category" component={WordList} />
			<Route path="/languages" component={LanguageList} />
			<Route path="/locations" component={LocationsList} />
			<Route path="/industries" component={IndustriesList} />
			<Route path="/companies" component={CompaniesList} />
			<Route path="/visitors" component={VisitorsList} />
			<Route path="/invoices" component={InvoicesList} />
			<Route path='/medialibrary' component={FileList} />
			<Route path='/assetmanagement' component={AssetList} />
		</Switch>
	</main>
);

export default Main;
