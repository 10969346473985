import { ListGroup } from "react-bootstrap";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import packageJson from "../package.json";

class Sidebar extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showProfile: false,
			showAdmin: false,
			impersonate: false,
			modules: ["placeholder"],
			languages: ["placeholder"],
		};

		Axios.get("/api/me").then((response) => {
			if (response.data.success) {
				this.setState({
					showProfile: response.data.user !== null,
					showAdmin: response.data.user.admin,
					modules: response.data.user.modules,
					languages: response.data.user.languages,
					impersonate: response.data.impersonate,
				});
			}

		});

		this.logout = this.logout.bind(this);
		this.stopImpersonate = this.stopImpersonate.bind(this);
	}

	logout(event) {
		if (event === null || event === undefined) {
			return;
		}

		Axios.get("/oauth/logout").then((_) => {
			window.location.href = "/";
		});
	}

	stopImpersonate(event) {
		if (event === null || event === undefined) {
			return;
		}


		Axios.get("/admin/users/exitImpersonate").then((response) => {
			if (response.data.success) {
				window.location.href = "/about";
			} else {
				console.log(response.data);
			}
		}).catch((e) => {
			console.log(e);
		});
	}

	render() {
		if (this.state.modules !== null) {
			return (
				<div
					style={{ backgroundColor: "transparent", height: "97%" }}
				>
					<div style={{ width: 250, margin: 20 }}>
						<img src="/Snapps_Logo.svg" alt="Snapps Logo" />
						<br />
						<br />
						<ListGroup>
							{this.state.impersonate === true ? (
								<ListGroup.Item>
									<a href="#" onClick={event => this.stopImpersonate(event)}>
										Stop Impersonating
									</a>
								</ListGroup.Item>
							) : (this.state.showProfile === true ? (
								<ListGroup.Item>
									<a href="#" onClick={event => this.logout(event)}>
										Logout
									</a>
								</ListGroup.Item>
							) : (
								<ListGroup.Item>
									<Link to="/login">Login</Link>
								</ListGroup.Item>
							))}
						</ListGroup>
						<br />
						{this.state.showProfile === true ? (
							<ListGroup>
								<ListGroup.Item>
									<Link to="/about">About</Link>
								</ListGroup.Item>
								{this.state.modules.indexOf("Product") !== -1 ? (
									<ListGroup.Item>
										<Link to="/products">Products</Link>
									</ListGroup.Item>
								) : null}
								{this.state.modules.indexOf("News") !== -1 || this.state.modules.indexOf("NewsDemo") !== -1 ? (
									<ListGroup.Item>
										<Link to="/posts">News{this.state.modules.indexOf("Demo") > -1 ? " - DEMO" : ""}</Link>
									</ListGroup.Item>
								) : null}
								{this.state.modules.indexOf("Survey") !== -1 ? (
									<ListGroup.Item>
										<Link to="/surveys">Survey</Link>
									</ListGroup.Item>
								) : null}
								{this.state.modules.indexOf("Language") !== -1 ? (
									<ListGroup.Item>
										<Link to="/languages">{this.state.languages.join(" & ")}</Link>
									</ListGroup.Item>
								) : null}
								{this.state.modules.indexOf("LanguageAnalytics") !== -1 ? (
									<ListGroup.Item>
										<Link to="/analytics/language">{this.state.languages.join(" & ")} Analytics</Link>
									</ListGroup.Item>
								) : null}
								{this.state.modules.indexOf("MediaLibrary") !== -1 ? (
									<ListGroup.Item>
										<Link to="/medialibrary">Media Library</Link>
									</ListGroup.Item>
								) : null}
								{this.state.modules.indexOf("AssetManagement") !== -1 ? (
									<ListGroup.Item>
										<Link to="/assetmanagement">Asset Management</Link>
									</ListGroup.Item>
								) : null}
								{this.state.modules.indexOf("Analytics") !== -1 ? (
									<ListGroup.Item>
										<Link to="/analytics">App Analytics</Link>
									</ListGroup.Item>
								) : null}
								{this.state.modules.indexOf("Directory") !== -1 ? (
									<div>
										<br />
										<ListGroup>
											<ListGroup.Item>
												<Link to="/locations">Locations</Link>
											</ListGroup.Item>
											<ListGroup.Item>
												<Link to="/industries">Industries</Link>
											</ListGroup.Item>
											<ListGroup.Item>
												<Link to="/companies">Companies</Link>
											</ListGroup.Item>
											{this.state.modules.indexOf("DirectoryMobileCheckin") !== -1 ? (
												<ListGroup.Item>
													<Link to="/visitors">Visitors</Link>
												</ListGroup.Item>
											) : null}
										</ListGroup>
									</div>
								) : null}
								{this.state.modules.indexOf("Invoice") !== -1 ? (
									<div>
										<br />
										<ListGroup>
											<ListGroup.Item>
												<Link to="/invoices">Invoices</Link>
											</ListGroup.Item>
										</ListGroup>
									</div>
								) : null}
								{this.state.showProfile ? (
									<div>
										<br />
										<ListGroup>
											<ListGroup.Item>
												<Link to="/support">Snapps Support</Link>
											</ListGroup.Item>
											<ListGroup.Item>
												<a href="https://snapps.statuspage.io/" target="_blank" rel="noopener noreferrer">Snapps Status</a>
											</ListGroup.Item>
											<ListGroup.Item>
												<Link to="/myInvoices">My Snapps Invoices</Link>
											</ListGroup.Item>
											{/* <ListGroup.Item>
												<Link to="/changelog">Changelog</Link>
											</ListGroup.Item> */}
										</ListGroup>
									</div>
								) : null}
								{this.state.showAdmin === true ? (
									<div>
										<br />
										<ListGroup>
											<ListGroup.Item>
												<Link to="/adminpanel">Admin Panel</Link>
											</ListGroup.Item>
										</ListGroup>
									</div>
								) : (
									<div />
								)}
							</ListGroup>
						) : (
							<div />
						)}
						<p style={{ textAlign: "center", paddingTop: 20 }}>Snapps v{packageJson.version}</p>
					</div>
				</div>
			);
		} else {
			return <div />;
		}
	}
}

export default Sidebar;
