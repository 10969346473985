import React from "react";

const AuthContext = React.createContext({});

const AuthProvider = AuthContext.Provider;
const AuthConsumer = AuthContext.Consumer;

class AuthSession {
	constructor(isLoggedIn, username, modules, languages) {
		this.isLoggedIn = isLoggedIn;
		this.username = username;
		if (modules !== undefined && modules !== null) {
			this.modules = modules;
		} else {
			this.modules = [];
		}

		if (languages !== undefined && languages !== null) {
			this.languages = languages;
		} else {
			this.languages = [];
		}
		this.admin = modules.indexOf("Admin") > -1;
	}
}

export { AuthConsumer, AuthProvider, AuthSession };