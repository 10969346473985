import React from "react";
import Helmet from "react-helmet";
import hostHelper from "./hostHelper";

const SEO = ({
	schema, title, description, path, contentType, twitter, imageURL
}) => (
	<Helmet>
		<title>{title}</title>
		<meta name="description" content={description} />
		<meta name="og:description" content={description} />
		<meta name="twitter:title" content={title} />
		{imageURL !== undefined ? <meta name="image" content={imageURL} /> : <meta name="image" content={hostHelper.api + "/api/5cde3f6a5415a72f8ab249be/profile/image"} />}
		{imageURL !== undefined ? <meta name="twitter:image:src" content={imageURL} /> : <meta name="twitter:image:src" content={hostHelper.api + "/api/5cde3f6a5415a72f8ab249be/profile/image"} />}
		{imageURL !== undefined ? <meta name="og:image" content={imageURL} /> : <meta name="og:image" content={hostHelper.api + "/api/5cde3f6a5415a72f8ab249be/profile/image"} />}
		{imageURL !== undefined ? <meta name="twitter:card" content={imageURL} /> : <meta name="twitter:card" content={hostHelper.api + "/api/5cde3f6a5415a72f8ab249be/profile/image"} />}
		<meta name="twitter:description" content={description} />
		<meta name="og:title" content={title} />
		<meta name="og:type" content={contentType} />
		<meta name="og:url" content={window.location.href} />
		<meta name="og:site_name" content="Snapps" />
		<meta name="og:locale" content="en_AU" />
		<meta name="fb:app_id" content="660864037754026" />
	</Helmet>
);


export default SEO;