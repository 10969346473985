var api;

if (window.location.host.indexOf("localhost") > -1) {
	api = "http://localhost:8080/v0";
} else {
	api = "https://api.snapps.co/v0";
}

export default {
	host: window.location.host,
	api,
};