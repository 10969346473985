import React from "react";
import { render } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./App";
import SEO from "./SEO";
import * as serviceWorker from "./serviceWorker";
import axios from "axios";
import hostHelper from "./hostHelper";
axios.defaults.baseURL = hostHelper.api;
axios.defaults.withCredentials = true;

axios.interceptors.response.use(function (response) {
	return response;
}, function (error) {
	if (window.location.pathname != "/login" && window.location.pathname.indexOf("/forgot") == -1 && window.location.pathname != "/") {
		window.location.href = "/login";
	}
	return Promise.reject(error);
});

render(
	<BrowserRouter>
		<SEO
			schema="SoftwareApplication"
			title="Snapps"
			description="Snapps is a native app platform for your business. No developer required!"
			path={window.location.pathname}
			contentType="website"
			twitter={null}
			imageURL={null}
		/>
		<ToastContainer />
		<App />
	</BrowserRouter>,
	document.getElementById("root")
);

serviceWorker.unregister();